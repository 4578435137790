<template>
  <page-section
    class="Guides"
    :page-title="$t('pages.support.guides.title')"
  >
    <template #above-title>
      <dashboard-filters-v2>
        <template #primary>
          <form-field
            left-icon="search"
            icon-class="hf-lg"
          >
            <input
              v-model.lazy="form.query"
              :placeholder="`${$t('forms.search')}...`"
              class="input is-transparent is-size-4 has-text-weight-semibold"
              data-testid="guideFilterInput"
            >
          </form-field>
        </template>
        <template #secondary>
          <dashboard-filters-v2-item>
            <dropdown-list
              :elements="types"
              :selected-elements.sync="form.types"
              has-checkboxes
              is-active-prop="slug"
              value-key="slug"
              key-by="slug"
              label-key="label"
              class="Guides__Search-TypePicker"
            >
              <template #trigger>
                <dashboard-filters-icon
                  :label="$t('pages.support.guides.type')"
                  class="DashboardFiltersV2-DropdownTrigger"
                  icon="video-o"
                  data-testid="typeOptions"
                />
              </template>
            </dropdown-list>
          </dashboard-filters-v2-item>
          <dashboard-filters-v2-item>
            <dropdown-list
              :elements="sections"
              :selected-elements.sync="form.sections"
              has-checkboxes
              is-active-prop="slug"
              value-key="slug"
              key-by="slug"
              label-key="label"
              class="Guides__Search-SectionPicker"
            >
              <template #trigger>
                <dashboard-filters-icon
                  :label="$t('pages.support.guides.section')"
                  class="DashboardFiltersV2-DropdownTrigger"
                  icon="section"
                  data-testid="sectionOptions"
                />
              </template>
            </dropdown-list>
          </dashboard-filters-v2-item>
        </template>
      </dashboard-filters-v2>
    </template>
    <div class="is-width-1/2-desktop">
      {{ $t('pages.support.guides.description') }}
    </div>
    <v-separator darker />
    <div class="m-b-l">
      <div
        v-masonry="'guideItems'"
        item-selector=".guide-item"
        column
        column-width="#guide-item-width"
        class="guide-items columns is-multiline is-variable-grid is-1"
      >
        <div v-if="!filteredGuidesFlat.length" class="column">
          {{ $t('pages.support.guides.no_guides') }}
        </div>
        <div id="guide-item-width" class="guide-item-grid-size" />
        <template v-for="(guide, index) in filteredGuidesFlat">
          <support-guide
            :key="guide.label"
            v-masonry-tile
            :guide="guide"
            class="guide-item column"
            :class="{ 'y2': index === 9, 'xy2': [0,11].includes(index) }"
          />
        </template>
      </div>
    </div>
  </page-section>
</template>

<script>
import Vue from 'vue'
import { TOUR_GUIDES } from '@/constants/tourGuides'
import SupportGuide from '@/components/support/SupportGuide'
import { VueMasonryPlugin } from 'vue-masonry'

Vue.use(VueMasonryPlugin)
/**
 * @module Guides
 */
export default {
  name: 'Guides',
  components: { SupportGuide },
  data () {
    return {
      form: {
        query: '',
        types: [],
        sections: []
      },
      sections: TOUR_GUIDES.map((section) => {
        return {
          label: section.title,
          slug: section.name
        }
      }),
      types: [
        {
          label: this.$t('pages.support.guides.types.video'),
          slug: 'video'
        }, {
          label: this.$t('pages.support.guides.types.tour'),
          slug: 'tour'
        }, {
          label: this.$t('pages.support.guides.types.article'),
          slug: 'article'
        }
      ],
      guides: TOUR_GUIDES
    }
  },
  computed: {
    filteredGuides () {
      return this.guides.map((section) => {
        const guides = section.guides.filter((guide) => {
          const types = this.form.types.length ? this.form.types.includes(guide.type) : true
          const query = guide.label.toLowerCase().includes(this.form.query.toLowerCase())
          return types && query
        })
        return {
          ...section,
          guides
        }
      }).filter((section) => {
        return section.guides.length && (this.form.sections.length ? this.form.sections.includes(section.name) : true)
      })
    },
    filteredGuidesFlat () {
      return this.filteredGuides
        .reduce((all, current) => {
          return all.concat(current.guides)
        }, [])
        .filter((guide, index, self) => self.findIndex(g => g.id === guide.id) === index)
    }
  },
  watch: {
    async filteredGuidesFlat () {
      await this.$nextTick()
      this.$redrawVueMasonry('guideItems')
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

$height: 180px;
$width: 25%;

$doubleWidth: $width*2;
$doubleHeight: $height*2;

.guide-item-grid-size {
  width: $width;
}

.guide-item {
  height: $height;
  width: 100%;

  @include tablet {
    width: $width;

    &.xy2 {
      width: $doubleWidth;
      height: $doubleHeight;
    }

    &.x2 {
      width: $doubleWidth;
    }

    &.y2 {
      height: $doubleHeight;
    }
  }
}
</style>
