<template>
  <a
    href="#"
    class="SupportGuide"
    @click.prevent="open"
  >
    <background-image
      shadow
      :src="guide.cover"
      class="is-full-height"
    >
      <div class="p-a-s is-full-height is-column">
        <div class="has-text-right">
          <round-icon
            :type="guide.type"
            size="large"
            icon-class="hf-2x"
            bg-color="black"
          />
        </div>
        <div class="m-t-a has-text-white is-size-5">
          <div class="SupportGuide__Label has-text-weight-bold">
            {{ guide.label }}
          </div>
          <div class="SupportGuide__Description leading-tight">
            {{ guide.description }}
          </div>
        </div>
      </div>
    </background-image>
  </a>
</template>

<script>
import { LocationService } from '@hypefactors/shared/js/services/LocationService'

/**
 * @module SupportGuide
 */
export default {
  name: 'SupportGuide',
  props: {
    guide: {
      type: Object,
      required: true
    }
  },
  methods: {
    open () {
      const type = this.guide.type
      if (type === 'video') {
        this.$bus.$emit('VideoDialog:open', this.guide.videoId)
      } else if (type === 'tour') {
        typeof Intercom !== 'undefined' && Intercom('startTour', this.guide.tourId)
      } else {
        LocationService.assign(this.guide.href)
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.SupportGuide {
  &:hover {
    color: inherit;
  }
}
</style>
